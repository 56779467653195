<template>
  <div>
    <van-collapse v-model="activeName" accordion>
      <van-collapse-item title="筛选条件" class="filter">
           <van-cell title="选择设备查询" is-link @click="filter.dev.show = true"/>
           <van-cell title="按时间段查询" :value="filter.time.value" is-link @click="filter.time.show = true"/>
           <van-row class="text-center">
              <van-col span="12"><van-button type="info" size="small" @click="resetData">重置</van-button></van-col>
              <van-col span="12"> <van-button type="info" size="small" @click="filterData">筛选</van-button></van-col>
           </van-row>
      </van-collapse-item>
    </van-collapse>
    <div class="chart-content">
         <div>
            <div class="title"><i class="fa fa-area-chart"></i>空气温度</div>
            <div v-if="loading">
                <van-loading type="spinner" vertical style="margin:7rem 0">加载中</van-loading>
            </div>
            <div v-else>
                <canvas id="airTemperatureChart" :class="autoFit == 1 ? 'greater10' : autoFit == 2 ? 'greater20': ''"></canvas>
            </div>
        </div>
        <div>
            <div class="title"><i class="fa fa-flag-checkered"></i>空气湿度</div>
            <div v-if="loading">
                <van-loading type="spinner" vertical style="margin:7rem 0">加载中</van-loading>
            </div>
            <div v-else>
                <canvas id="airHumidnessChart" :class="autoFit == 1 ? 'greater10' : autoFit == 2 ? 'greater20': ''"></canvas>
            </div>
        </div>
        <div>
            <div class="title"><i class="fa fa-line-chart"></i>路面温度</div>
            <div v-if="loading">
                <van-loading type="spinner" vertical style="margin:7rem 0">加载中</van-loading>
            </div>
            <div v-else>
                <canvas id="roadTemperatureChart" :class="autoFit == 1 ? 'greater10' : autoFit == 2 ? 'greater20': ''"></canvas>
            </div>
        </div>
        <div>
            <div class="title"><i class="fa fa-bar-chart"></i>能见度</div>
            <div v-if="loading">
                <van-loading type="spinner" vertical style="margin:7rem 0">加载中</van-loading>
            </div>
            <div v-else>
                <canvas id="visibilityChart" :class="autoFit == 1 ? 'greater10' : autoFit == 2 ? 'greater20': ''"></canvas>
            </div>
        </div>
    </div>
    <!-- 筛选设备 -->
    <van-popup v-model="filter.dev.show" round position="bottom" :style="{ height: '45%' }" >
        <div class="filter-dev-area">
            <van-checkbox-group v-model="filter.dev.value">
                <van-cell-group>
                    <van-cell
                    v-for="(item, index) in filter.dev.columns"
                    clickable
                    :key="item.devCode"
                    :title="item.devName"
                    @click="toggle(index)"
                    >
                    <template #right-icon>
                        <van-checkbox :name="item.devCode" ref="checkboxes" />
                    </template>
                    </van-cell>
                </van-cell-group>
            </van-checkbox-group>
        </div>
    </van-popup>
    <!-- 筛选时间段 -->
    <van-calendar ref="time" v-model="filter.time.show" type="range" :min-date="filter.time.minDate" :max-date="filter.time.maxDate" @confirm="timeConfirm" color="#48a9f9"/>
    <tabbar />
  </div>
</template>

<script>
const F2 = require('@antv/f2');
import tabbar from '@/components/melves/tabBar.vue'
export default {
  components: {
    tabbar
  },
  data() {
    return {
      activeName: '',
      loading:true,
      filter:{
        dev:{
          value:[],
          show:false,
          columns:[]
        },
        time:{
          start:'',
          end:'',
          minDate: new Date(2018, 0, 1),
          maxDate: new Date(),
          value:'',
          show:false
        }
      },
      melvesVesion:null,
      autoFit:0,
    }
  },
  methods:{
    // 格式化日期显示
    formatDate(date) {
      return `${date.getFullYear().toString()}-${this.fillZero(date.getMonth() + 1)}-${this.fillZero(date.getDate())}`;
    },
    // 日期补零
    fillZero(str) {
        let realNum;
        str < 10 ? realNum = '0' + str : realNum = str;
        return realNum;
    },
    toggle(index) {
      this.$refs.checkboxes[index].toggle();
    },
    // 筛选时间确认事件
    timeConfirm(date) {
      const [start, end] = date;
      this.filter.time.show = false;
      this.filter.time.start = `${this.formatDate(start)}`;
      this.filter.time.end = `${this.formatDate(end)}`;
      this.filter.time.value = `${this.filter.time.start} 至 ${this.filter.time.end}`;
    },
    // 重置
    resetData(){
      this.filter.dev.value = [];
      this.filter.time.value='';
      this.filter.time.start = '';
      this.filter.time.end = '';
      this.$refs.time.reset();
    },
    // 筛选
    filterData(){
      this.loading = true;
      this.activeName = '';
      this.getChartInfo();
    },
    // 获取数据
    getChartInfo(){
      if(this.melvesVesion === 'M2'){
       // 新版
       this.$api.MELVES.statisticsInfoM2({
         projectCode:this.projectCode,
         devCode:this.filter.dev.value.length>0 ? this.filter.dev.value.join(',') : void 0,
         beginTime:this.filter.time.start ? `${this.filter.time.start} 00:00:00` : void 0,
         endTime:this.filter.time.end ? `${this.filter.time.end} 00:00:00` : void 0,
       }).then( data =>{
          if(data.length < 10){
            this.autoFit = 0;
          }else if(data.length >= 10 && data.length < 20){
            this.autoFit = 1;
          }else if(data.length >= 20){
            this.autoFit = 2;
          }
          var airTemperatureArr = []; // 空气温度
          var airHumidnessArr = []; // 空气湿度
          var roadTemperatureArr = []; // 路面温度
          var visibilityArr = []; // 能见度
          for(let i=0;i<data.length;i++){
              data[i].time.forEach((e,index)=>{
                  airTemperatureArr.push({
                      time:e.substring(0,e.lastIndexOf(':')),
                      airTemperature:parseFloat(data[i].airTemperature[index]),
                      name:data[i].devName
                  });
                  airHumidnessArr.push({
                    time:e.substring(0,e.lastIndexOf(':')),
                    airHumidness:parseFloat(data[i].airHumidness[index]),
                    name:data[i].devName
                  });
                  roadTemperatureArr.push({
                    time:e.substring(0,e.lastIndexOf(':')),
                    roadTemperature:parseFloat(data[i].roadTemperature[index]) === -273 ? 0 : parseFloat(data[i].roadTemperature[index]),
                    name:data[i].devName
                  });
                  visibilityArr.push({
                    time:e.substring(0,e.lastIndexOf(':')),
                    visibility:parseFloat(data[i].visibility[index]),
                    name:data[i].devName
                  });
              })
          }
          this.airTemperatureArr = airTemperatureArr;
          this.airHumidnessArr = airHumidnessArr;
          this.roadTemperatureArr = roadTemperatureArr;
          this.visibilityArr = visibilityArr;
          this.loading = false;
          this.showChart();
       })
      }else{
       // 旧版
       this.$api.MELVES.statisticsInfo({
         projectCode:this.projectCode,
         devCode:this.filter.dev.value.length>0 ? this.filter.dev.value.join(',') : void 0,
         beginTime:this.filter.time.start ? `${this.filter.time.start} 00:00:00` : void 0,
         endTime:this.filter.time.end ? `${this.filter.time.end} 00:00:00` : void 0,
       }).then( data =>{
          if(data.length < 10){
            this.autoFit = 0;
          }else if(data.length >= 10 && data.length < 20){
            this.autoFit = 1;
          }else if(data.length >= 20){
            this.autoFit = 2;
          }
          var airTemperatureArr = []; // 空气温度
          var airHumidnessArr = []; // 空气湿度
          var roadTemperatureArr = []; // 路面温度
          var visibilityArr = []; // 能见度
          for(let i=0;i<data.length;i++){
              data[i].time.forEach((e,index)=>{
                  airTemperatureArr.push({
                      time:e.substring(0,e.lastIndexOf(':')),
                      airTemperature:parseFloat(data[i].airTemperature[index]),
                      name:data[i].devName
                  });
                  airHumidnessArr.push({
                    time:e.substring(0,e.lastIndexOf(':')),
                    airHumidness:parseFloat(data[i].airHumidness[index]),
                    name:data[i].devName
                  });
                  roadTemperatureArr.push({
                    time:e.substring(0,e.lastIndexOf(':')),
                    roadTemperature:parseFloat(data[i].roadTemperature[index]),
                    name:data[i].devName
                  });
                  visibilityArr.push({
                    time:e.substring(0,e.lastIndexOf(':')),
                    visibility:parseFloat(data[i].visibility[index]),
                    name:data[i].devName
                  });
              })
          }
          this.airTemperatureArr = airTemperatureArr;
          this.airHumidnessArr = airHumidnessArr;
          this.roadTemperatureArr = roadTemperatureArr;
          this.visibilityArr = visibilityArr;
          this.loading = false;
          this.showChart();
       })
      }
    },
    // 绘制数据
    showChart(){
      this.$nextTick(()=>{
          // 生成空气温度图表
          const chart1 = new F2.Chart({
            id: 'airTemperatureChart',
            pixelRatio: window.devicePixelRatio
          });
          chart1.source(this.airTemperatureArr);
          chart1.scale('time', {
            type: 'timeCat',
            mask: "MM-DD HH:mm",
            tickCount: 4,
            range:[0,1]
          });
          chart1.scale('airTemperature', {
            tickCount: 6
          });
          chart1.axis('time', {
            label: function label(text, index, total) {
              const textCfg = {};
              if (index === 0) {
                textCfg.textAlign = 'left';
              } else if (index === total - 1) {
                textCfg.textAlign = 'right';
              }
              return textCfg;
            }
          });
          
          chart1.tooltip({
            custom: true, // 自定义 tooltip 内容框
            showXTip: true, // 是否展示 X 轴的辅助信息
            xTip: (obj)=>{
              return obj
            },
            onChange: function onChange(obj) {
              const legend = chart1.get('legendController').legends.top[0];
              const tooltipItems = obj.items;
              const legendItems = legend.items;
              const map = {};
              legendItems.forEach(function(item) {
                map[item.name] = item;
              });
              tooltipItems.forEach(function(item) {
                const name = item.name;
                const value = item.value;
                if (map[name]) {
                  map[name].value = value;
                }
              });
              legend.setItems(Object.values(map));
            },
            onHide: function onHide() {
              const legend = chart1.get('legendController').legends.top[0];
              legend.setItems(chart1.getLegendItems().country);
            }
          });
          chart1.line({
            connectNulls: true // 将空数据连接
          }).shape('smooth').position('time*airTemperature').color('name');
          chart1.render();

          // 生成湿度图
          const chart2 = new F2.Chart({
            id: 'airHumidnessChart',
            pixelRatio: window.devicePixelRatio
          });
          chart2.source(this.airHumidnessArr);
          chart2.scale('time', {
            type: 'timeCat',
            mask: "MM-DD HH:mm",
            tickCount: 4,
            range:[0,1]
          });
          chart2.scale('airHumidness', {
            tickCount: 5
          });
          chart2.axis('time', {
            label: function label(text, index, total) {
              const textCfg = {};
              if (index === 0) {
                textCfg.textAlign = 'left';
              } else if (index === total - 1) {
                textCfg.textAlign = 'right';
              }
              return textCfg;
            }
          });
          chart2.tooltip({
            custom: true, // 自定义 tooltip 内容框
            showXTip: true, // 是否展示 X 轴的辅助信息
            xTip: (obj)=>{
              return obj
            },
            onChange: function onChange(obj) {
              const legend = chart2.get('legendController').legends.top[0];
              const tooltipItems = obj.items;
              const legendItems = legend.items;
              const map = {};
              legendItems.forEach(function(item) {
                map[item.name] = item;
              });
              tooltipItems.forEach(function(item) {
                const name = item.name;
                const value = item.value;
                if (map[name]) {
                  map[name].value = value;
                }
              });
              legend.setItems(Object.values(map));
            },
            onHide: function onHide() {
              const legend = chart2.get('legendController').legends.top[0];
              legend.setItems(chart2.getLegendItems().country);
            }
          });
          chart2.line().position('time*airHumidness').color('name');
          chart2.render();

          // 生成路面温度图
          const chart3 = new F2.Chart({
            id: 'roadTemperatureChart',
            pixelRatio: window.devicePixelRatio
          });
          chart3.source(this.roadTemperatureArr);
          chart3.scale('time', {
            type: 'timeCat',
            mask: "MM-DD HH:mm",
            tickCount: 4,
            range:[0,1]
          });
          chart3.scale('roadTemperature', {
            tickCount: 5
          });
          chart3.axis('time', {
            label: function label(text, index, total) {
              const textCfg = {};
              if (index === 0) {
                textCfg.textAlign = 'left';
              } else if (index === total - 1) {
                textCfg.textAlign = 'right';
              }
              return textCfg;
            }
          });
          chart3.tooltip({
            custom: true, // 自定义 tooltip 内容框
            showXTip: true, // 是否展示 X 轴的辅助信息
            xTip: (obj)=>{
              return obj
            },
            onChange: function onChange(obj) {
              const legend = chart3.get('legendController').legends.top[0];
              const tooltipItems = obj.items;
              const legendItems = legend.items;
              const map = {};
              legendItems.forEach(function(item) {
                map[item.name] = item;
              });
              tooltipItems.forEach(function(item) {
                const name = item.name;
                const value = item.value;
                if (map[name]) {
                  map[name].value = value;
                }
              });
              legend.setItems(Object.values(map));
            },
            onHide: function onHide() {
              const legend = chart3.get('legendController').legends.top[0];
              legend.setItems(chart3.getLegendItems().country);
            }
          });
          chart3.line().position('time*roadTemperature').color('name');
          chart3.render();

          // 生成能见度图
          const chart4 = new F2.Chart({
            id: 'visibilityChart',
            pixelRatio: window.devicePixelRatio
          });
          chart4.source(this.visibilityArr);
          chart4.scale('time', {
            type: 'timeCat',
            mask: "MM-DD HH:mm",
            tickCount: 4,
            range:[0,1]
          });
          chart4.scale('visibility', {
            tickCount: 5
          });
          chart4.axis('time', {
            label: function label(text, index, total) {
              const textCfg = {};
              if (index === 0) {
                textCfg.textAlign = 'left';
              } else if (index === total - 1) {
                textCfg.textAlign = 'right';
              }
              return textCfg;
            }
          });
          chart4.tooltip({
            custom: true, // 自定义 tooltip 内容框
            showXTip: true, // 是否展示 X 轴的辅助信息
            xTip: (obj)=>{
              return obj
            },
            onChange: function onChange(obj) {
              const legend = chart4.get('legendController').legends.top[0];
              const tooltipItems = obj.items;
              const legendItems = legend.items;
              const map = {};
              legendItems.forEach(function(item) {
                map[item.name] = item;
              });
              tooltipItems.forEach(function(item) {
                const name = item.name;
                const value = item.value;
                if (map[name]) {
                  map[name].value = value;
                }
              });
              legend.setItems(Object.values(map));
            },
            onHide: function onHide() {
              const legend = chart4.get('legendController').legends.top[0];
              legend.setItems(chart4.getLegendItems().country);
            }
          });
          chart4.line().position('time*visibility').color('name');
          chart4.render();
      })
    },
    // 获取设备信息
    getDevInfo(){
      this.$api.MELVES.allDevInfo({
         projectCode:this.projectCode
      }).then( d => {
        this.filter.dev.columns = d
      })
    }
  },
  mounted(){
      this.projectCode = window.sessionStorage.getItem('projectCode');
      this.melvesVesion = window.sessionStorage.getItem('melvesVesion');
      this.getChartInfo();
      this.getDevInfo();
  }
}
</script>
<style lang="scss" scoped>
.filter{text-align: left;}
.text-center{
  text-align: center;
  button{
    padding: 0 20px;
    margin-top: 5%;
  }
}
.filter-dev-area{
    text-align: left;
    padding: 20px;
    overflow-y: auto;
}
#airTemperatureChart,
#airHumidnessChart,
#roadTemperatureChart,
#visibilityChart{width: 100%;height: 16rem;}
.greater10{
  height: 25rem !important;
}
.greater20{
  height: 35rem !important;
}
.title{
  text-align: left;font-size: .95rem;line-height:2;padding-left: 4%;
  .fa{color: #13a597;font-size: 1.2rem;padding-right: 2%;}
}
.chart-content{
    padding-top: 10px;
    padding-bottom: 22%;
}
</style>